const appConstants = {
  ALERT_ERROR: 'ALERT_ERROR',
  ALERT_SUCCESS: 'ALERT_SUCCESS',

  APP_LOADING: 'APP_LOADING',

  PROJECT_REQUEST: 'PROJECT_REQUEST',
  PROJECT_SUCCESS: 'PROJECT_SUCCESS',
  PROJECT_ERROR: 'PROJECT_ERROR',

  SKILL_REQUEST: 'PROJECT_REQUEST',
  SKILL_SUCCESS: 'SKILL_SUCCESS',
  SKILL_ERROR: 'SKILL_ERROR',

  BLOG_REQUEST: 'BLOG_REQUEST',
  BLOG_SUCCESS: 'BLOG_SUCCESS',
  BLOG_ERROR: 'BLOG_ERROR',

  DRAWER_OPEN: 'DRAWER_OPEN',
  DRAWER_CLOSED: 'DRAWER_CLOSED',

};

export default appConstants;
